/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import ButtonInstance from "../components/ButtonInstance";
import EmailActionSuggestions from "../components/EmailActionSuggestions";
import styles from "./EmailHome.module.css";
import User from "../models/userModel";
import { v4 as uuidv4 } from "uuid";
import ChatAssistantDropDown2 from "./ChatAssistantDropdown2";
import { useEffect, useRef, useState } from "react";
import GlobalVariables from "../models/globalVariables";
import MessageText from "../components/MessageText";
import Assistant from "../models/assistantModel";
import AddyWebSocket from "../models/addyWebSocket";
import { useNavigate, useLocation } from "react-router-dom";
import EmailActionNavigationBreadCrumbs from "../components/EmailActionNavigationBreadCrumbs";
import BackgroundSearch from "./BackgroundSearch";

// eslint-disable-next-line no-undef
const hostName = window.location.host;
let lastScroll = 0;

window.chatId = "new";
let computedEmbeddings = null;

const EmailHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);

  const maxWidth = urlParams.get("maxWidth") || "300px";
  // const maxHeight = urlParams.get("maxHeight") || "100vh";

  const assistantTopSelectRef = useRef(null);
  const dropdownRef = useRef(null);
  const chatHistoryRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  // const [CHAT_ID, setChatID] = useState("new");
  // eslint-disable-next-line no-unused-vars
  const [selectedChatHistory, setSelectedChatHistory] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [settings, setSettings] = useState(null);
  const [referecedElementId, setReferencedElementId] = useState(
    "addy-header-assistant-select"
  );
  const [defaultAssistant, setDefaultAssistant] = useState(null);
  const [settingsIcon, setSettingsIcon] = useState("/settings-grey.svg");
  const [emailSummary, setEmailSummary] = useState("");
  const [customInstructions, setCustomInstructions] = useState([]);
  const [selectedAttachmentName, setSelectedAttachmentName] =
    useState("My attachment.pdf");
  const [showComposeEmailTooltip, setShowComposeEmailTooltip] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentlyTyping, setCurrentlyTyping] = useState(false);

  const [userSubscriptionInfo, setUserSubscriptionInfo] = useState(null);
  // eslint-disable-next-line max-len
  // Current AI Email Action Can be "Reply quickly", "Follow up", "Analyze attachment",
  // "Compose", "loan opportunity", "Background Search"
  const [currentEmailAction, setCurrentEmailAction] = useState(null);
  const [currentView, setCurrentView] = useState("pre-chat");
  const [suggestedReplyApproaches, setSuggestedReplyApproaches] = useState([]);
  const [sendersIntent, setSendersIntent] = useState("");

  const [
    initialEmailActionSuggestionsView,
    setInitialEmailActionSuggestionsView,
  ] = useState("suggestions");

  const [assistants, setAssistants] = useState([]);
  const [messageTexts, setMessageTexts] = useState([]);
  const [documentsContext, setDocumentsContext] = useState([]); 

  const data = urlParams.get("data") ? JSON.parse(urlParams.get("data")) : {};

  // console.log("Data passed to email home", data);
  let uid = data?.uid || null;
  const email = data?.email || null;
  const token = data?.token || null;
  const clientID = data?.clientID || null;
  const currentEmailActionSent = data?.currentEmailAction || null;
  const name = data?.name || "You";
  const clientHostname = data?.clientHostname || hostName;
  const defaultUserSettings = data?.defaultUserSettings || null;
  const selectedAttachmentNameSent = data?.selectedAttachmentName || null;
  const suggestedReplyApproachesSent = data?.suggestedReplyApproaches || null;
  const initialEmailActionSuggestionsViewSent =
    data?.initialEmailActionSuggestionsView;

  const sendersIntentSent = data?.sendersIntent || null;
  const whichFeature = data?.whichFeature || null;

  const [emailThread, setEmailThread] = useState(null);
  const [documents, setDocuments] = useState(null);
  // let attachmentListBase64 = null;
  const [attachmentListBase64, setAttachmentListBase64] = useState(null);
  const [contactsInCurrentThread, setContactsInCurrentThread] = useState(null);

  // const clientID = urlParams.get("clientID");
  // const data = urlParams.get("data") ? JSON.parse(urlParams.get("data")) : {};

  // let showOnboarding = urlParams.get("showOnboarding");

  // Initialize a new user model
  const user = new User(uid, token, email);

  // Event handler for click events
  const handleClickOutside = (event) => {
    // If user clicks outside or inside of dropdown ref
    // Close the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
      if (
        !(
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          assistantTopSelectRef.current &&
          !assistantTopSelectRef.current.contains(event.target)
        )
      ) {
        setShowDropdown(true);
      }
    }
  };

  const initializeDefaultAssistant = (assistants) => {
    // console.log("Initializing default assistant", assistants);
    const defaultAssistant = assistants.find(
      (assistant) =>
        assistant &&
        assistant.priority &&
        assistant.priority.toLowerCase() === "default"
    );

    if (defaultAssistant) {
      // Set default assistant on frontened
      setDefaultAssistant(defaultAssistant);
      setSelectedAssistant(defaultAssistant);
    }

    if (!defaultAssistant && assistants.length > 1) {
      // Set on frontend and update on backend
      setDefaultAssistant(assistants[0]); // Pick the first assistant
      // updateDefaultAssistant(assistants[1], "First Time Use");
    } else if (!defaultAssistant && assistants.length === 1) {
      setDefaultAssistant(assistants[0]);
    }
  };

  const getWebSocketURL = async (id) => {
    // Make fetch request to get the websocket URL
    const API_URL = GlobalVariables.getAPIURL();
    return await fetch(
      // eslint-disable-next-line max-len
      `${API_URL}/webSocket/webSocket/get-websocket-url?uid=${uid}&clientId=${id}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data?.webSocketURL;
      })
      .catch((error) => {
        console.error("Error getting websocket URL: ", error);
        return null;
      });
  };

  const getUserSubscription = async () => {
    const userSubscriptionObject = await getSubscriptonFromServer(uid);
    if (userSubscriptionObject) {
      setUserSubscriptionInfo(userSubscriptionObject);
    }
  };

  async function getSubscriptonFromServer(uid) {
    const API_URL = GlobalVariables.getAPIURL();
    return await fetch(`${API_URL}/api/user/subscription?uid=${uid}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          return data;
        } else {
          return undefined;
        }
      })
      .catch(() => {
        return undefined;
      });
  }

  async function fetchResponseData(requestData, endpoint, attributeToget) {
    const data = requestData;
    let returnVal;
    await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          returnVal = data[attributeToget];
        } else {
          returnVal = undefined;
        }
      })
      .catch((error) => {
        returnVal = undefined;
      });
    return returnVal;
  }

  const getSendersIntentAndPossibleWaysToReply = () => {
    const API_URL_DIRECT_INTENT = GlobalVariables.getAPIURL() + "/api";
    const data = {
      uid: uid,
      authEmail: email,
      subscription: "unlimited",
      email: emailThread,
      sender: name,
      language: defaultUserSettings?.language || "english",
    };
    const intent = fetchResponseData(
      data,
      `${API_URL_DIRECT_INTENT}/thread/intent`,
      "response"
    );
    const approaches = fetchResponseData(
      data,
      `${API_URL_DIRECT_INTENT}/thread/suggested-ways`,
      "response"
    );

    Promise.all([intent, approaches]).then(([intent, approaches]) => {
      // console.log("Successfully generated intents");
      if (intent && intent.length && approaches && approaches.length) {
        // update state
        setSendersIntent(intent);
        setSuggestedReplyApproaches(approaches);
      }
    });
  };

  const getEmailSummaryFromAI = (emailThread) => {
    const data = {
      uid: uid,
      authEmail: email,
      subscription: "unlimited",
      formattedThread: emailThread,
      summaryLengthWords: 30,
    };
    const endpoint =
      GlobalVariables.getAPIURL() + "/emailSummary/summary/summary";
    // console.log("Getting email summary");
    fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const summary = data["response"];
          // console.log("Summary:", summary);
          setEmailSummary(summary);
        } else {
          throw new Error("SummaryResponseNotOK");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const windowListenForPostMessage = () => {
    window.addEventListener("message", (event) => {
      const origin = event.origin || event.originalEvent.origin;
      // console.log("Origin", origin);

      const data = event.data;
      // console.log("Data received from window post message", data);
      if (data && data.key) {
        const action = data.key;
        const value = data.value;
        switch (action) {
          case "emailThread":
            setEmailThread(value);
            console.log(
              "Email thread received via window post message length: ",
              value?.length
            );
            if (!value) {
              setCurrentEmailAction("Compose");
            }
            if (value && value.length && !emailSummary) {
              getEmailSummaryFromAI(value);
            }
            if (
              value &&
              value.length &&
              !(sendersIntent && suggestedReplyApproaches)
            ) {
              getSendersIntentAndPossibleWaysToReply();
            }
            break;
          case "computedEmbeddings":
            computedEmbeddings = value;
            // console.log("Computed embeddings received: ", computedEmbeddings);
            break;
          case "document":
            setDocuments(value);
            if (value && value.length) {
              console.log("Documents received", value);
              setCurrentEmailAction("Analyze document");
            }
            break;
          case "sendersIntent": {
            // console.log("Suggested reply approaches", text);
            const suggestedReplyApproaches =
              JSON.parse(value)?.suggestedReplyApproaches;
            const sendersIntent = JSON.parse(value)?.sendersIntent;
            // console.log("Suggested reply approaches", suggestedReplyApproaches);
            // console.log("Sender's intent", sendersIntent);
            if (suggestedReplyApproaches) {
              // Set suggested reply approaches
              setSuggestedReplyApproaches(suggestedReplyApproaches);
            }
            if (sendersIntent) {
              // Set senders intent
              setSendersIntent(sendersIntent);
            }
            break;
          }
          case "emailSummary": {
            const summary = JSON.parse(value)?.summary;
            if (summary) {
              // Set summary
              setEmailSummary(summary);
            }
            break;
          }
          case "customInstructions": {
            const customInst = value?.customInstructions;
            if (customInst) {
              // Set custom instructions
              setCustomInstructions(customInst);
            }
            break;
          }
          case "attachmentListBase64":
            setAttachmentListBase64(value);
            if (value && value.length) {
              setCurrentEmailAction("Analyze attachment");
            }
            break;

          case "peopleInCurrentThread":
            // Set the contacts in current thread
            setContactsInCurrentThread(value);
            break;

          case "settings":
            // Client settings received from client - Set the settings
            setSettings(value);
            break;

          case "buttonClicked":
            {
              if (
                value?.computedEmbeddings &&
                value?.computedEmbeddings.length
              ) {
                computedEmbeddings = value?.computedEmbeddings;
              }
              if (value?.buttonName === "Follow up") {
                const mostUpdatedThread = value?.emailThread;
                // console.log("Email thread here", mostUpdatedThread);
                if (mostUpdatedThread && mostUpdatedThread.length) {
                  setEmailThread(mostUpdatedThread);
                  // Reset the chat and chatID
                  // setMessageTexts([]);
                  window.chatId = "new";
                }
                // Now click on follow up with most updated thread
                onFollowUpEmailClick(mostUpdatedThread);
              }
              if (value?.buttonName === "Reply quickly") {
                const mostUpdatedThread = value?.emailThread;
                // console.log("Email thread here", mostUpdatedThread);
                if (mostUpdatedThread && mostUpdatedThread.length) {
                  setEmailThread(mostUpdatedThread);
                  // Reset the chat and chatID
                  // setMessageTexts([]);
                  window.chatId = "new";
                }
                // Now stage the reply quickly view
                onReplyQuicklyClickedFromChromeExtension();
              }
            }
            break;
          case "directSuggestedReplyApproachClicked": {
            if (value?.computedEmbeddings && value?.computedEmbeddings.length) {
              computedEmbeddings = value?.computedEmbeddings;
            }
            const way = value?.way;
            const intent = value?.intent;
            const mostUpdatedThread = value?.emailThread;
            if (mostUpdatedThread && mostUpdatedThread.length) {
              setEmailThread(mostUpdatedThread);
              // Reset the chat and chatID
              // setMessageTexts([]);
              window.chatId = "new";
            }
            // Now click suggested reply approach
            onSuggestedReplyApproachClickedFromChromeExtension(
              way,
              intent,
              mostUpdatedThread
            );
            break;
          }
          default:
            break;
        }
      }
    });
  };

  const listenForSelectedFromClientWebpage = async (id) => {
    const webSocketURL = await getWebSocketURL(id);
    console.log("WebSocket URL: ", webSocketURL);
    if (!webSocketURL) return;

    const ws = new WebSocket(`${webSocketURL}/${id}`);
    // Create onError event handler
    ws.onerror = function (error) {
      console.error("WebSocket Error:", error);
    };
    // Connection established
    ws.onopen = function () {
      console.log("Socket Connected successfully!", id);
      // If there's no email thread try to request it from client
      if (!emailThread) {
        console.log("No email thread asking client for email thread");
        AddyWebSocket.retrySendDataToWebsocket(
          clientID,
          `${clientID}-webpage`,
          "sendEmailThread"
        );
      }
    };

    ws.onmessage = function (event) {
      // console.log("Message received on react: ", event.data);
      if (event.data) {
        // Parse event.data to an object
        const data = JSON.parse(event.data);
        const action = data.action;
        const text = data.text;

        switch (action) {
          case "emailThread":
            setEmailThread(text);
            if (text && text.length && !emailSummary) {
              getEmailSummaryFromAI(text);
            }
            if (
              text &&
              text.length &&
              !(sendersIntent && suggestedReplyApproaches)
            ) {
              getSendersIntentAndPossibleWaysToReply();
            }
            break;
          case "computedEmbeddings":
            computedEmbeddings = text;
            // console.log("Computed embeddings received: ", computedEmbeddings);
            break;
          case "document":
            setDocuments(text);
            break;
          case "sendersIntent": {
            // console.log("Suggested reply approaches", text);
            const suggestedReplyApproaches =
              JSON.parse(text)?.suggestedReplyApproaches;
            const sendersIntent = JSON.parse(text)?.sendersIntent;
            if (suggestedReplyApproaches) {
              // Set suggested reply approaches
              setSuggestedReplyApproaches(suggestedReplyApproaches);
            }
            if (sendersIntent) {
              // Set senders intent
              setSendersIntent(sendersIntent);
            }
            break;
          }
          case "emailSummary": {
            const summary = JSON.parse(text)?.summary;
            if (summary) {
              // Set summary
              setEmailSummary(summary);
            }
            break;
          }
          case "customInstructions": {
            const customInst = JSON.parse(text)?.customInstructions;
            if (customInst) {
              // Set custom instructions
              setCustomInstructions(customInst);
            }
            break;
          }
          case "attachmentListBase64":
            setAttachmentListBase64(text);
            break;
          default:
            break;
        }
      }
    };
  };

  const onDocumentAnalyzeClick = () => {
    setShowComposeEmailTooltip(true);
    setCurrentEmailAction("Analyze document");
  };

  const updateLastEmailActionInEmailClientWebpage = (action) => {
    AddyWebSocket.retrySendDataToWebsocket(
      action,
      `${clientID}-webpage`,
      "emailActionChange",
      5
    );
  };

  const handleOnScroll = () => {
    // Get the breadcrumb nav
    // Get the breadcrumb nav
    const breadcrumbNav = document.getElementById("bread-crumbs-nav");
    // If scrolling to the bottom in small-chat-container
    // const smallChatContainer = document.querySelector(".small-chat-container");
    const suggestionsViewContainer = document.getElementById(
      "email-action-suggestions-container"
    );
    if (
      chatHistoryRef?.current?.clientHeight &&
      chatHistoryRef.current.scrollHeight
    ) {
      const currentScroll = chatHistoryRef.current.scrollTop;
      // console.log("Current scroll 1", currentScroll, chatHistoryRef.current.clientHeight, chatHistoryRef.current.scrollHeight);
      if (currentScroll > lastScroll) {
        // Scrolling to the bottom, make the opacity 0
        // console.log("here in scrolling to the bottom", currentScroll, chatHistoryRef.current.scrollHeight);
        if (breadcrumbNav) {
          breadcrumbNav.style.opacity = 0;
        }
      } else if (currentScroll <= lastScroll) {
        // Scrolling to the top, make the opacity 1
        // console.log("here in scrolling to the top", currentScroll, lastScroll);
        if (breadcrumbNav) {
          breadcrumbNav.style.opacity = 1;
        }
      }
      lastScroll = currentScroll;
    } else if (
      suggestionsViewContainer.clientHeight &&
      suggestionsViewContainer.scrollHeight
    ) {
      const currentScroll = suggestionsViewContainer.scrollTop;
      if (currentScroll > lastScroll) {
        // Scrolling to the bottom, make the opacity 0
        // console.log("Scrolling to the bottom", currentScroll, suggestionsViewContainer.scrollHeight);
        if (breadcrumbNav) {
          breadcrumbNav.style.opacity = 0;
        }
      } else if (currentScroll <= lastScroll) {
        // Scrolling to the top, make the opacity 1
        if (breadcrumbNav) {
          breadcrumbNav.style.opacity = 1;
        }
      }
      lastScroll = currentScroll;
    }
  };

  useEffect(() => {
    windowListenForPostMessage();
    // eslint-disable-next-line no-undef
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("wheel", handleOnScroll);
    setSettingsIcon("/settings-grey.svg");
    // listenForSelectedFromClientWebpage(clientID);

    if (currentEmailActionSent) {
      // setCurrentEmailAction("Analyze attachment");
      setCurrentEmailAction(currentEmailActionSent);
      if (currentEmailActionSent === "loan opportunity") {
        onExtractLoanOpportunityClick(attachmentListBase64);
      }
      if (currentEmailActionSent === "Background Search") {
        onRunBackgroundSearchClick();
      }
    }
    if (selectedAttachmentNameSent) {
      setSelectedAttachmentName(selectedAttachmentNameSent);
    }
    if (suggestedReplyApproachesSent) {
      setSuggestedReplyApproaches(suggestedReplyApproachesSent);
    }
    if (sendersIntentSent) {
      setSendersIntent(sendersIntentSent);
    }
    if (initialEmailActionSuggestionsViewSent) {
      setInitialEmailActionSuggestionsView(
        initialEmailActionSuggestionsViewSent
      );
    }

    if (uid && !userSubscriptionInfo) {
      getUserSubscription();
    }

    // Set assistants
    user.getAssistants().then((assistants) => {
      setAssistants(assistants);
      if (assistants.length && !selectedAssistant) {
        // Set the first assistant as the selected assistant
        setSelectedAssistant(assistants[0]);
      }
      if (assistants.length) {
        // Initialize the default assistant
        initializeDefaultAssistant(assistants);
      }
    });
    // Clean up by removing the event listener on unmount
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("wheel", handleOnScroll);
    };
  }, []);

  // Re-render the chat when the email thread, computed embeddings, or attachment list changes
  useEffect(() => {}, [
    emailThread,
    computedEmbeddings,
    attachmentListBase64,
    emailSummary,
    customInstructions,
  ]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    if (chatHistoryRef.current && messageTexts.length) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
    // console.log("Selected assistant in use effect", selectedAssistant);
  }, [messageTexts, selectedAssistant]);

  useEffect(() => {
    const missingInfo = []
    const infoToNotInclude = [
      "dealState",
      "dealAmount",
      "dealPipeline",
      "dealType",
      "dealName",
      "email",
      "brokerType",
      "assignedAgent",
      "dealStage",
      "brokerageIncludingGST",
      "establishmentFee",
      "interestRateHigherRate",
      "interestRateLowerRate",
    ];
    if (location?.state?.loanInformation) {
      for(let key in location?.state?.loanInformation) {
        for(let key2 in location?.state?.loanInformation[key]) {
          if(location?.state?.loanInformation[key][key2]["confidence"] <= 3 || location?.state?.loanInformation[key][key2]["value"] === "N/A" || location?.state?.loanInformation[key][key2]["value"] === "") {
            if(infoToNotInclude.indexOf(location?.state?.loanInformation[key][key2]["attribute"]) === -1) {
              missingInfo.push(
                location?.state?.loanInformation[key][key2]["attribute"]
              );
            }
          }
        }
      }
    }
    if (location?.state?.writeLoan) {
      setCurrentEmailAction("Missing info");
      uid = location?.state?.uid;
      const prompt = "Write an email to get missing loan information -.-." + missingInfo.join(", ");
      onGenerateButtonClicked(prompt, "", location?.state?.emailThread);
    }
  }, [window.location.pathname]);

  const onAssistantClick = (assistant) => {
    setSelectedAssistant(assistant);
    setShowDropdown(false);
  };

  const updateDefaultAssistant = (assistant, e) => {
    // Click event did not come form the item
    if (!e) return;
    // Set the default assistant in the frontend and backend
    setDefaultAssistant(assistant);
    user.setDefaultAssistant(assistant.appID);
    // eslint-disable-next-line max-len
    // if (assistant.appID !== "addy-default-assistant-c66e0609-371e-49b2-bb4a-5186526b2a9d") {
    //     // Update the default assistant in the backend
    //     user.setDefaultAssistant(assistant.appID);
    // }
  };

  const onExtractLoanOpportunityClick = (attachmentListBase641) => {
    // use react router to go to the /form route passing the necessary props
    // eslint-disable-next-line no-undef
    navigate("/form", {
      state: {
        data: data,
        emailThread: emailThread,
        attachmentListBase64: attachmentListBase641,
      },
    });
    setCurrentEmailAction("loan opportunity"); // to extract loan opportunity
    updateLastEmailActionInEmailClientWebpage("loan opportunity");
  };

  const onRunBackgroundSearchClick = () => {
    // The contact has name and email
    setCurrentEmailAction("Background Search");
    setCurrentView("chat");
  };

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onComposeEmailInMyVoiceClick = () => {
    setShowComposeEmailTooltip(true);
  };

  const onReplyQuicklyClickedFromChromeExtension = () => {
    setCurrentEmailAction("Reply quickly");
    setInitialEmailActionSuggestionsView("staging");
  };

  const onSuggestedReplyApproachClickedFromChromeExtension = (
    way,
    intent,
    mostUpdatedThread
  ) => {
    onSuggestedReplyApproachClick(way, -1, intent, mostUpdatedThread);
  };

  const onFollowUpEmailClick = (mostUpdatedThread) => {
    // Follow up and reply use the same function
    setCurrentEmailAction("Follow up");
    setCurrentView("chat");
    // console.log("Follow up email clicked");
    onGenerateButtonClicked(
      "Write a follow up to this email",
      "",
      mostUpdatedThread
    );
    setInitialEmailActionSuggestionsView("suggestions");
  };

  const onContactsDropdownChange = (selectedContact) => {
    // Update contacts in current thread and where selectedContact.email === email,
    // set that contact to backgroundSearch = true and make sure only one contact is set to backgroundSearch = true
    const updatedContacts = contactsInCurrentThread.map((contact) => {
      if (contact.email === selectedContact.email) {
        contact.backgroundSearch = true;
      } else {
        contact.backgroundSearch = false;
      }
      return contact;
    });
    setContactsInCurrentThread(updatedContacts);
  };
  const onSuggestedReplyApproachClick = (
    way,
    index,
    intent,
    mostUpdatedThread
  ) => {
    // Populate the message text
    const promptId =
      messageTexts.length === 0
        ? "reply-chat-init-001"
        : "reply-chat-continue-001";
    const requestParams = {
      sender_intent: intent,
      selected_response: way,
      thread: mostUpdatedThread || emailThread,
      language: defaultUserSettings?.language || "english",
      tone: defaultUserSettings?.tone || "neutral",
      name: name,
    };
    onSendButtonClick(way, intent, "Sender's intent", promptId, requestParams);
  };

  const onGenerateButtonClicked = async (
    prompt,
    selectedText,
    mostUpdatedThread,
    rephrase = false
  ) => {
    // Decide whic prompt to use
    let promptId =
      messageTexts.length === 0
        ? "reply-chat-init-001"
        : "reply-chat-continue-001";
    if (currentEmailAction === "Compose") {
      promptId =
        messageTexts.length === 0
          ? "compose-email-personalized-init-001"
          : "reply-chat-continue-001";
    }
    if (currentEmailAction === "Analyze attachment") {
      // @Jay this is where you can add the prompt id for analyzing attachments
      // The prompt Id could be different based on whether it's the first message or not
      promptId = "analyze-attachment-001";
    }
    if (currentEmailAction === "Analyze document") {
      promptId = "analyze-document-001";
    }
    if (currentEmailAction === "Follow up") {
      promptId =
        messageTexts.length === 0
          ? "follow-up-001-single"
          : "reply-chat-continue-001";
    }

    if (rephrase) {
      promptId = "reply-chat-rephrase-001";
    }

    if (currentEmailAction === "Missing info") {
      promptId = "loan-missing-info-hubspot-001";
    }

    const requestParams = {
      thread: mostUpdatedThread || emailThread,
      language: defaultUserSettings?.language || "english",
      tone: defaultUserSettings?.tone || "neutral",
      name: name,
      user_prompt: prompt,
      ...(currentEmailAction === "Analyze attachment" ? { attachmentListBase64: attachmentListBase64 } : { documents: documents }),
    };

    if (promptId === "reply-chat-init-001") {
      requestParams["sender_intent"] = sendersIntent;
      requestParams["selected_response"] = prompt;
    }
    if(promptId === "loan-missing-info-hubspot-001") { 
      requestParams["missingFields"] = prompt.split("Write an email to get missing loan information -.-.")[1].split(", ");
    }
    // console.log("Request params", requestParams);
    const selectedTextTitle =
      currentEmailAction === ("Analyze attachment" || "Analyze document") ? "Document" : "";
    onSendButtonClick(
      prompt,
      selectedText,
      selectedTextTitle,
      promptId,
      requestParams
    );
  };

  // eslint-disable-next-line no-unused-vars
  const onSendButtonClick = async (
    prompt,
    selectedText,
    selectedTextTitle,
    promptId,
    requestParams
  ) => {
    if (!prompt || prompt === "" || prompt.trim === "") {
      // eslint-disable-next-line no-undef
      alert("Prompt cannot be empty");
      return;
    }
    // Change the view to chat to show the chat messages
    if (currentView === "pre-chat") setCurrentView("chat");
    // Append the user's message to the chat
    const userMessageId = uuidv4();
    const userMessageText = userMessage(
      prompt,
      selectedText,
      userMessageId,
      selectedTextTitle
    );
    setMessageTexts((prevMessageTexts) => [
      ...prevMessageTexts,
      userMessageText,
    ]);
    // Create Thinking Text and append to messageTexts
    const assistantMessageId = uuidv4();
    const assistantMessageTextBuffer = assistantMessage(
      "",
      "thinking",
      assistantMessageId
    );
    setMessageTexts((prevMessageTexts) => [
      ...prevMessageTexts,
      assistantMessageTextBuffer,
    ]);
    // Get the assistant's response
    // console.log("Selected assistant", selectedAssistant);
    console.log("Chat id to send", window.chatId);
    console.log("Computed embeddings to send", computedEmbeddings);
    const responses = await Assistant.getEmailReplyChat(
      selectedAssistant?.appID,
      selectedAssistant?.publicId,
      uid,
      hostName,
      window.chatId,
      prompt,
      selectedText,
      name,
      clientHostname,
      selectedChatHistory,
      requestParams,
      email,
      promptId,
      computedEmbeddings,
      customInstructions
    );

    // Get the last assistant message which was thinking
    let assistantMessageElement = document.getElementById(
      `${assistantMessageId}`
    );
    if (!assistantMessageElement) {
      // console.error("Assistant message element not found", assistantMessageId);
      await sleep(10); // Sleep for 10 ms to wait for the assistant message to be rendered
      assistantMessageElement = document.getElementById(
        `${assistantMessageId}`
      );
      if (!assistantMessageElement) {
        console.error(
          "Assistant message element not found after waiting",
          assistantMessageId
        );
        return;
      } else {
        // console.log("Assistant message element found after waiting", assistantMessageId);
      }
    }

    // Type in the assistant's response
    const messageText = assistantMessageElement.querySelector(
      ".model-response-text"
    );
    const feedbackContainer = assistantMessageElement.querySelector(
      ".feedback-container"
    );
    let firstChunk = true;
    // Set currently typing to be true
    setCurrentlyTyping(true);

    for await (const response of responses) {
      let text;
      if (typeof response === "string") {
        
        if (response.includes("documents-fetched-")) {
          const documents = JSON.parse(response.split("documents-fetched-")[1]);
          const docObj = {
            documents: documents,
            messageId: messageTexts.length + 1,
          }
          console.log("Documents fetched", docObj);
          setDocumentsContext([...documentsContext, docObj]);
          text = "";
        } else {
          text = response.split("data: ")[1] || response;
        }
      } else {
        // console.error("Response is not a string:", response);
        continue; // Skip the current iteration as response isn't a string.
      } // Produce a typing effect for the text
      if (firstChunk && text.length > 0) {
        // Remove the loading part of the thinking element
        const thinkingElement = assistantMessageElement.querySelector(".dot");
        if (thinkingElement) thinkingElement.remove();

        // Make the message text visible
        messageText.classList.remove("hide");
        firstChunk = false;
      }
      // Create a typing effect
      for (let i = 0; i < text.length; i++) {
        // Append messageText message attribute to text at character i
        setMessageTexts((prevMessageTexts) => {
          const newMessageTexts = [...prevMessageTexts];
          newMessageTexts[newMessageTexts.length - 1].message += text.charAt(i);
          return newMessageTexts;
        });

        await sleep(5); // Sleep for 5 milliseconds to create typing effect
        // Scroll to the bottom of the chat
        const chatContainer = document.getElementById("addy-chat-history");
        if (chatContainer) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
        // if (chatHistoryRef.current) {
        //     console.log("i am scrolling", chatHistoryRef, chatHistoryRef.current.scrollHeight);
        //     chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        // }
      }
    }
    // Set currently typing to be false
    setCurrentlyTyping(false);
    feedbackContainer.classList.remove("hide");
    /*
                // Save the chat locally
                saveCurrentChatLocally();
                */
  };

  const onBreadcrumbHomeClick = () => {
    setCurrentView("pre-chat");
    setInitialEmailActionSuggestionsView("suggestions");
    // reset chat messages
    setMessageTexts([]);
    // reset the current email action
    setCurrentEmailAction(currentEmailActionSent);
    if (whichFeature && currentEmailActionSent === "Background Search") {
      setCurrentEmailAction(whichFeature);
    }
  };

  const userMessage = (message, selectedText, messageId, selectedTextTitle) => {
    return {
      message: message,
      selectedText: selectedText,
      name: name,
      type: "user",
      messageId: messageId,
      selectedTextTitle: selectedTextTitle
        ? selectedTextTitle
        : "Selected text",
      imageURL: "https://i.imgur.com/W1yNbq7.png",
    };
  };

  const onRephraseButtonClick = (message) => {
    onGenerateButtonClicked(
      "Rephrase the generated email",
      "",
      emailThread,
      true
    );
  };

  const assistantMessage = (message, action, messageId) => {
    return {
      message: message,
      name: selectedAssistant?.appName || "Addy AI",
      type: "assistant",
      messageId: messageId,
      action: action || "none",
      imageURL: "/assistant-icon.svg",
    };
  };

  return (
    <div
      className={styles.chat}
      style={{
        maxWidth: maxWidth,
        // height: maxHeight,
        // minHeight: "620px",
      }}
    >
      <main className={styles.top}>
        <header
          className="top-bar-new-message"
          style={{
            paddingLeft: "10px",
            zIndex: 99999,
          }}
        >
          <div
            className="f-r-a-m-e"
            style={{
              display: "flex",
              flexGrow: 1,
              maxWidth: "fit-content",
            }}
          >
            <img
              className="addy-check-security tooltip-1"
              width="16"
              height="16"
              src="/lock-icon-24.png"
              data-toggle="tooltip"
              title="Your chat is private, secure, and only visible to you"
              style={{
                marginRight: "0px",
                paddingRight: "8px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // flexGrowth: 1,
              }}
            >
              <div
                className="button-parent"
                style={
                  {
                    // gap: "10px",
                  }
                }
              >
                <div
                  ref={assistantTopSelectRef}
                  className="button"
                  id="addy-header-assistant-select"
                  style={{
                    maxWidth: "150px",
                  }}
                  data-toggle="tooltip"
                  title="Choose assistant"
                  onClick={() => {
                    setReferencedElementId("addy-header-assistant-select");
                    setShowDropdown(true);
                  }}
                >
                  <div className="addy-assistant">
                    {selectedAssistant
                      ? selectedAssistant.appName.length > 17
                        ? `${selectedAssistant.appName.substring(0, 16)}...`
                        : selectedAssistant.appName
                      : "Loading..."}
                  </div>
                  <img className="plus-icon" alt="" src="/plus.svg" />
                  <img
                    className="expand-icon tooltip-2"
                    alt=""
                    src="/expand.svg"
                  />
                </div>
                {/* Show assistants drop down */}
                {showDropdown && (
                  <div>
                    <ChatAssistantDropDown2
                      displayPosition="bottom"
                      assistants={assistants}
                      selectedAssistant={selectedAssistant}
                      referencedElementId={referecedElementId}
                      smallContainer={true}
                      defaultAssistant={defaultAssistant}
                      onAssistantClick={onAssistantClick}
                      updateDefaultAssistant={updateDefaultAssistant}
                      headerText="Assistants"
                      createNewLink="https://app.addy-ai.com"
                      ref={dropdownRef}
                    />
                  </div>
                )}
                <div className="open-in-full-parent tooltip-3">
                  {/* <img
                                        loading="lazy"
                                        alt="Settings"
                                        src={settingsIcon}
                                        data-toggle="tooltip"
                                        title="AI response settings"
                                    /> */}
                  {/* These images are being added
                            from the chrome extension
                            page to close and expand the sidebar chat */}
                  {/* <img
                                className="open-in-full"
                                loading="lazy"
                                alt=""
                                src="/open-in-full.svg"
                            />
                            <img
                                className="close-icon"
                                loading="lazy"
                                alt=""
                                src="/close.svg"
                            /> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        <section
          className={`${styles.textField}`}
          style={{
            maxWidth: maxWidth,
            // minHeight: "100%",
            height: "100vh",
          }}
        >
          <div
            id="email-action-suggestions-container"
            className={styles.welcomeAndSuggestionsContainer}
          >
            {currentView === "pre-chat" && (
              <EmailActionSuggestions
                emailSummary={emailSummary}
                emailThread={emailThread}
                documents={documents}
                attachmentListBase64={attachmentListBase64}
                contactsInCurrentThread={contactsInCurrentThread}
                onContactsDropdownChange={onContactsDropdownChange}
                onRunBackgroundSearchClick={onRunBackgroundSearchClick}
                currentEmailAction={currentEmailAction}
                initialEmailActionSuggestionsView={
                  initialEmailActionSuggestionsView
                }
                suggestedReplyApproaches={suggestedReplyApproaches}
                onSuggestedReplyApproachClick={onSuggestedReplyApproachClick}
                onComposeEmailInMyVoiceClick={onComposeEmailInMyVoiceClick}
                onFollowUpEmailClick={onFollowUpEmailClick}
                userSubscriptionInfo={userSubscriptionInfo}
                sendersIntent={sendersIntent}
                uid={uid}
                email={email}
                name={name}
                onExtractLoanOpportunityClick={() =>
                  onExtractLoanOpportunityClick(attachmentListBase64)
                }
                language={defaultUserSettings?.language || "english"}
              />
            )}
          </div>
          <div
            className={`${styles.frameParent} small-chat-container ${
              currentView === "chat" ? "height-100-percent" : ""
            }`}
          >
            {currentView === "chat" && (
              <div
                ref={chatHistoryRef}
                id="addy-chat-history"
                style={{
                  height: "100%",
                }}
              >
                {currentEmailAction === "Background Search" && (
                  <div
                    style={{
                      paddingBottom: "20px",
                    }}
                  >
                    <br />
                    <BackgroundSearch
                      settings={settings}
                      sendersIntent={sendersIntent}
                      contactsInCurrentThread={contactsInCurrentThread}
                    />
                  </div>
                )}
                {messageTexts.map((item, index) => {
                  return (
                    <MessageText
                      documents={documentsContext.filter(doc => doc.messageId === index)[0]?.documents}
                      message={item}
                      key={index}
                      onRephraseButtonClick={() => {
                        onRephraseButtonClick(item);
                      }}
                      clientID={clientID}
                      minWidth="1"
                      isFirstMessage={index == 0}
                      // eslint-disable-next-line max-len
                      isLastMessage={index == messageTexts.length - 1}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <ButtonInstance
            // hideFeedbackRequestText={true}
            maxCharsPerLine={22}
            hideHotTipContainer={true}
            currentEmailAction={currentEmailAction}
            selectedAttachment={{
              name: selectedAttachmentName,
              tag: attachmentListBase64?.length ? attachmentListBase64[0].tag || "" : ""
            }}
            selectedDocument={documents?.length ? documents[0] || null : null}
            clientID={clientID}
            currentlyTyping={currentlyTyping}
            uid={uid}
            numberOfMessagesInChat={messageTexts.length}
            // hideSuggestedPrompts={messageTexts.length > 0 ? true : false}
            hideSuggestedPrompts={true}
            showMoreSuggestionsForYouTitle={false}
            onClick={(prompt, selectedText) =>
              onGenerateButtonClicked(prompt, selectedText)
            }
          />
        </section>
      </main>
      {currentView === "chat" && (
        <div
          className={styles.breadCrumbsNavParent}
          id="bread-crumbs-nav"
          style={{
            minWidth: `${
              currentEmailAction === "Background Search"
                ? "180px"
                : "fit-content"
            }`,
          }}
        >
          <EmailActionNavigationBreadCrumbs
            currentEmailAction={currentEmailAction}
            breadCrumbsHomeClick={() => onBreadcrumbHomeClick()}
          />
        </div>
      )}

      {showComposeEmailTooltip && (
        <div onClick={() => setShowComposeEmailTooltip(false)}>
          <div id="react-joyride-portal">
            <div
              className="react-joyride__overlay"
              data-test-id="overlay"
              role="presentation"
              style={{
                cursor: "pointer",
                height: 703,
                pointerEvents: "auto",
                inset: 0,
                overflow: "hidden",
                position: "absolute",
                zIndex: 100,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                mixBlendMode: "hard-light",
              }}
            >
              <div
                className="react-joyride__spotlight"
                data-test-id="spotlight"
                style={{
                  borderRadius: 4,
                  position: "absolute",
                  backgroundColor: "gray",
                  height: 65,
                  left: 0,
                  opacity: 1,
                  pointerEvents: "auto",
                  bottom: 33,
                  transition: "opacity 0.2s ease 0s",
                  width: 300,
                }}
              ></div>
            </div>
          </div>

          <div id="" style={{ zIndex: 200, border: "1px solid red" }}>
            <div
              className="__floater __floater__open"
              x-placement="top"
              style={{
                display: "inline-block",
                filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px)",
                maxWidth: "100%",
                opacity: 1,
                position: "absolute",
                transition: "opacity 0.3s ease 0s, transform 0.2s ease 0s",
                visibility: "visible",
                zIndex: 200,
                padding: "0px 0px 16px",
                willChange: "transform",
                top: -20,
                left: -20,
                transform: "translate3d(30px, 386px, 0px)",
              }}
            >
              <div className="__floater__body">
                <div
                  aria-label="Ask Your Assistant Anything"
                  className="react-joyride__tooltip"
                  aria-modal="true"
                  role="alertdialog"
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    borderRadius: 5,
                    boxSizing: "border-box",
                    color: "rgb(51, 51, 51)",
                    fontSize: 16,
                    maxWidth: "100%",
                    padding: 15,
                    position: "relative",
                    width: 280,
                  }}
                >
                  <div style={{ lineHeight: 1.4, textAlign: "center" }}>
                    <h1
                      aria-label="Ask Your Assistant Anything"
                      style={{ fontSize: 18, margin: 0 }}
                    >
                      {currentEmailAction !== "Analyze document"
                        ? `Write your prompt below`
                        : `Ask about the document below`}
                    </h1>

                    <div style={{ padding: "20px 10px" }}>
                      {currentEmailAction !== "Analyze document"
                        ? `
                            Simply type what your email is about. For instance:
                            "Write an email to schedule a meeting", then click the
                            arrow button.`
                        : `Simply type what you want to know about the document. For
                                instance: "Summarize the document", then click the
                                arrow button.`}
                    </div>
                  </div>

                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 15,
                    }}
                  >
                    <div style={{ flex: "1 1 0%" }}></div>
                    {/* <button data-test-id="button-back" type="button" aria-label="Back" data-action="back" role="button" title="Back"
                                        style={{backgroundColor: "transparent", border: 0, borderRadius: 0, color: "rgb(113, 80, 224)", cursor: "pointer", fontSize: 16, lineHeight: 1, padding: 8, appearance: "none", marginLeft: "auto", marginRight: 5}}>Back</button> */}

                    <button
                      onClick={() => setShowComposeEmailTooltip(false)}
                      data-test-id="button-primary"
                      type="button"
                      aria-label="Last"
                      data-action="primary"
                      role="button"
                      title="Last"
                      style={{
                        backgroundColor: "rgb(113, 80, 224)",
                        border: 0,
                        borderRadius: 4,
                        color: "rgb(255, 255, 255)",
                        cursor: "pointer",
                        fontSize: 16,
                        lineHeight: 1,
                        padding: 8,
                        appearance: "none",
                      }}
                    >
                      <span>&nbsp;&nbsp;Got it&nbsp;&nbsp;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailHome;
