export const samplePrompts = ["Summarize a document", "Generate project outline",
    "Create a to-do list", "Write a blog post", "Generate a list of ideas", "Write a summary",
    "Write a poem about love", "Tell me a joke", "What happened in 1969", "Give me a travel itinerary",
    "Write a book review", "Write a product description", "Write a movie review", "Write a song",
    "Advise on quick stress relief", "Translate 'Hello' to French", "Write a short story",
    "Brief history of the internet", "Write a recipe", "Write a love letter",
    "Write a speech", "How to make a paper airplane", "Write a letter to a friend",
    "What are the benefits of meditation", "Write a letter to the editor", "Write a letter to a company",
    "Explain how photosynthesis works", "Generate a tech startup name"
];

export const sampleSelectedTextActions = ["Improve writing", "Summarize", "Make shorter", "Translate"];

export const mortgagePrompts = [
    "Summarize a loan application",
    "Write a client follow-up email",
    "Create a to-do list for loan processing",
    "Draft a loan pre-approval letter",
    "Tips for new loan officers",
    "Write a blog post about mortgage loans",
    "Write a loan investment memo",
    "What is LTV in mortgage lending",
    "What is CLTV in mortgage lending",
    "What are interest rates",
    "Why do lending rates change?",
    "Write a summary of loan options",
    "Tell me a mortgage industry trend",
    "Write a loan origination report template",
    "Write a mortgage market analysis report",
    "Draft an email to a mortgage broker",
    "Advise on improving credit scores",
    "Write a success story of a closed loan",
    "Case study template of successful loan approval",
    "Write a checklist for first-time homebuyers",
    "Write a thank-you letter to a client",
    "How to explain loan terms to clients",
    "Email a loan underwriter",
    "Benefits of loan refinancing",
    "Explain how loan amortization works",
    "Generate a loan marketing campaign idea"
];

export const documentPrompts = {
    "Bank Statements" : [
        "Are there any large cash deposits that need explanation?",
        "Are there any ACH transfers that don't list the name of the employer?",
        "What percentage of the deposits are large cash deposits relative to the borrower's gross income?",
        "Can you flag deposits that exceed 50% of the borrower's gross income?",
        "List all deposits that are unexplained or irregular.",
        "Are there more than three unexplained deposits?",
        "Can you highlight any potential red flags in the bank statement?",
        "Do any of the cash deposits raise concerns based on their frequency or size?",
        "Are there any unusual patterns in withdrawals or transfers?",
        "Is there any indication of recurring income sources from employers or businesses?",
    ],
    "W-2": [
        "What is the total gross income reported on this W-2?",
        "Can you verify the employer's name and EIN on the W-2?",
        "Is the reported income consistent with previous years' earnings?",
        "What is the amount of federal income tax withheld?",
        "How much Social Security tax was withheld?",
        "Can you identify any discrepancies in the wage and tax reporting?",
        "Are there any signs of multiple employers or job changes during the year?",
        "Can you break down the state and local tax withholdings?",
        "What is the amount of Medicare tax withheld?",
        "Does the income match the borrower's stated earnings on the application?",
    ],
    "W-9": [
        "What is the taxpayer's full name and business name, if applicable?",
        "Can you confirm the taxpayer's Social Security Number (SSN) or Employer Identification Number (EIN)?",
        "Is the individual or entity correctly classified (Individual, Corporation, LLC, etc.)?",
        "What is the taxpayer's address listed on the W-9?",
        "Is the taxpayer exempt from backup withholding?",
        "Can you verify the signature and date on the form?",
        "Is the taxpayer's information consistent with other documents provided?",
        "Are there any discrepancies in the taxpayer's filing status?",
        "Can you confirm if the taxpayer is a U.S. citizen or resident?",
        "Does the form indicate any exemptions, and are they correctly applied?",
    ],
    "1099": [
        "What is the total income reported on this 1099 form?",
        "Can you confirm the payer's name, address, and Taxpayer Identification Number (TIN)?",
        "Does the recipient's information (name, TIN, address) match the loan application?",
        "What is the type of income reported (e.g., nonemployee compensation, interest, dividends)?",
        "Can you identify if any federal income tax was withheld?",
        "What is the amount of any state income tax withheld?",
        "Are there any discrepancies between this 1099 and other income documents?",
        "Can you confirm if the income is recurring or one-time?",
        "Are there multiple sources of 1099 income listed?",
        "Does the income match the borrower's stated earnings on the application?",
    ],
    "Paystubs": [
        "What is the total gross income for this pay period?",
        "Can you identify the year-to-date (YTD) gross income?",
        "How much federal income tax was withheld in this pay period?",
        "Can you verify the employer's name and address?",
        "What is the net income after all deductions for this pay period?",
        "How much was withheld for Social Security and Medicare taxes?",
        "Are there any unusual deductions or garnishments listed?",
        "Does the pay frequency (weekly, bi-weekly, monthly) match the borrower's stated earnings?",
        "Can you break down state and local tax withholdings?",
        "Are there any overtime or bonuses reflected in the paystub?",
    ],
    "Tax Returns (Form 1040)": [
        "What is the total adjusted gross income (AGI) reported on this 1040?",
        "Can you confirm the total taxable income?",
        "What is the amount of federal income tax owed or refunded?",
        "Can you verify the filer's name, Social Security Number (SSN), and address?",
        "What are the total wages, salaries, and tips reported on this return?",
        "Is there any self-employment income reported, and if so, how much?",
        "What deductions or credits were claimed, and how do they impact the total tax liability?",
        "Are there any capital gains or losses reported?",
        "Can you confirm if any income was earned from rental properties or other passive income sources?",
        "Is there a significant discrepancy between this tax return and other income documents?",
    ],
    "Credit Report": [
        "What is the borrower's current credit score?",
        "Can you list any delinquent accounts or late payments?",
        "Are there any collections or charge-offs reported?",
        "What is the borrower's total debt-to-income ratio?",
        "Can you identify any recent hard inquiries on the credit report?",
        "What is the borrower's total outstanding debt?",
        "Are there any high balances close to or over the credit limit on any accounts?",
        "Can you summarize the borrower's open and active credit lines?",
        "Are there any public records like bankruptcies, foreclosures, or liens?",
        "Can you confirm if there are any signs of potential identity theft or fraud on the report?",
    ],
    "Purchase Agreement": [
        "What is the total purchase price of the property?",
        "Can you verify the names of the buyer(s) and seller(s)?",
        "What is the agreed-upon closing date?",
        "Are there any contingencies listed in the agreement (e.g., financing, inspection)?",
        "What amount of earnest money has been deposited?",
        "Can you confirm the property address and legal description?",
        "What are the terms of the seller's concessions, if any?",
        "Are there any special conditions or addendums attached to the agreement?",
        "What is the breakdown of the buyer's down payment and financing details?",
        "Can you verify the timeline for any required inspections or appraisals?",0
    ],
    "Title": [
        "Who is listed as the legal owner of the property?",
        "Are there any liens or encumbrances on the title?",
        "Can you verify the property's legal description?",
        "Are there any easements or rights-of-way affecting the property?",
        "What is the title insurance coverage amount?",
        "Is there any mention of unpaid property taxes?",
        "Are there any restrictions or covenants associated with the property?",
        "Can you confirm if the title is clear and marketable?",
        "What are the dates and details of previous ownership transfers?",
        "Are there any unresolved title issues that need to be addressed?",
    ],
    "Appraisal": [
        "What is the appraised value of the property?",
        "Can you confirm the property's address and legal description?",
        "Are there any discrepancies between the appraised value and the purchase price?",
        "What comparable properties were used in determining the appraisal value?",
        "Are there any noted repairs or conditions that affect the property's value?",
        "What is the property's square footage and lot size according to the appraisal?",
        "Does the appraisal mention any issues with the property's location or neighborhood?",
        "Are there any adjustments made to the comparables, and why?",
        "Can you confirm the appraiser's qualifications and licensing?",
        "Were any environmental or zoning issues noted in the appraisal?",
    ],
    "Loan Application (Form 1003)": [
        "What is the borrower's requested loan amount?",
        "Can you confirm the borrower's current employment and income details?",
        "What is the property address and type (primary residence, investment, etc.)?",
        "What is the borrower's declared monthly housing expense?",
        "Can you verify the borrower's assets and liabilities?",
        "What is the borrower's declared credit score or any noted credit concerns?",
        "Is there a co-borrower listed, and if so, what are their details?",
        "What type of loan is the borrower applying for (FHA, VA, Conventional, etc.)?",
        "Can you verify the down payment amount and its source?",
        "Are there any red flags or inconsistencies between the loan application and other documents?",
    ],
    "Lending Policy": [
        "What is the minimum credit score required for loan approval?",
        "What are the maximum loan-to-value (LTV) ratios for different loan types?",
        "Can you list the acceptable forms of collateral for secured loans?",
        "What are the income verification requirements for borrowers?",
        "Are there any restrictions on the type of properties eligible for financing?",
        "What is the policy on debt-to-income (DTI) ratio limits?",
        "Can you explain the conditions under which a borrower might need to provide additional documentation?",
        "What are the guidelines for approving loans with co-borrowers?",
        "Are there any exceptions or waivers available in the lending policy?",
        "What is the policy on interest rate determination and adjustments?",
    ],
    "Closing Disclosure": [
        "What is the total loan amount on the Closing Disclosure?",
        "Can you confirm the borrower's interest rate and loan terms?",
        "What are the total closing costs for the borrower?",
        "Can you break down the monthly mortgage payment, including principal, interest, taxes, and insurance (PITI)?",
        "What is the total amount of cash to close required from the borrower?",
        "Are there any prepaid items or escrow payments included in the disclosure?",
        "Can you verify the details of any seller-paid closing costs?",
        "What is the loan's annual percentage rate (APR) listed on the disclosure?",
        "Are there any adjustments to the loan terms or fees from the initial Loan Estimate?",
        "Can you confirm the disbursement dates and any key dates related to closing?",
    ],
}
