import FRAME from "../components/FRAME";
import ButtonInstance from "../components/ButtonInstance";
import "./Chat.css";
import { useEffect, useRef, useState } from "react";
import ChatAssistantDropDown2 from "./ChatAssistantDropdown2";
import MessageText from "../components/MessageText";
import User from "../models/userModel";
import Assistant from "../models/assistantModel";
// eslint-disable-next-line no-unused-vars
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import { v4 as uuidv4 } from "uuid";
import ChatHistoryDropdown from "../components/ChatHistoryDropdown";
import BackgroundSearch from "./BackgroundSearch";
import sampleHtml from "./sampleHtml";
import InjectHTML from "../components/InjectHTML";

// eslint-disable-next-line no-undef
const hostName = window.location.host;

const Chat = () => {
  // Get URL search params, uid, email, token, name.
  // eslint-disable-next-line no-undef
  const urlParams = new URLSearchParams(window.location.search);

  const uid = urlParams.get("uid");
  const email = urlParams.get("email");
  const token = urlParams.get("token");
  const name = urlParams.get("name") || "You";
  const clientID = urlParams.get("clientID");
  const selectedAssistantID = urlParams.get("selectedAssistantID");
  const clientHostname = urlParams.get("clientHostname") || hostName;
  const data = urlParams.get("data") ? JSON.parse(urlParams.get("data")) : {};

  let hideHotTip = urlParams.get("hideHotTip") || "false";
  hideHotTip = hideHotTip === "true";

  let showOnboarding = urlParams.get("showOnboarding");

  // eslint-disable-next-line no-undef
  if (
    sessionStorage.getItem("onboardingHasBeenShown") === null &&
    showOnboarding === "true"
  ) {
    // eslint-disable-next-line no-undef
    sessionStorage.setItem("onboardingHasBeenShown", "true");
  } else {
    showOnboarding = "false";
  }

  // Initialize a new user model
  const user = new User(uid, token, email);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [chatHistoryReferencedElementId, setChatHistoryReferencedElementId] =
    useState(null);
  const [referecedElementId, setReferencedElementId] = useState(null);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [defaultAssistant, setDefaultAssistant] = useState(null);
  const [currentView, setCurrentView] = useState("select-assistant");
  const [messageTexts, setMessageTexts] = useState([]);
  const [CHAT_ID, setChatID] = useState(uuidv4());
  const [selectedChatHistory, setSelectedChatHistory] = useState(null);

  const formatChatName = clientHostname
    ? clientHostname.replace("www.", "")
    : getDateFormat();
  const [chatName, setChatName] = useState(formatChatName);

  const [tooltipRun, setTooltipRun] = useState(showOnboarding === "true");
  const [tooltipSteps, setTooltipSteps] = useState([]);
  const [currentlyTyping, setCurrentlyTyping] = useState(false);
  const [documentsContext, setDocumentsContext] = useState([]); 

  // const [currentMessage, setCurrentMessage] = useState(null);

  // Ref for dropdown wrapper
  const dropdownRef = useRef(null);
  const assistantTopSelectRef = useRef(null);
  const chatHistoryInfoRef = useRef(null);
  const bigAssistantsDropdownRef = useRef(null);
  const chatHistoryRef = useRef(null);
  const chatNameDropdownRef = useRef(null);

  // Event handler for click events
  const handleClickOutside = (event) => {
    // If user clicks outside or inside of dropdown ref
    // Close the dropdown
    if (
      bigAssistantsDropdownRef.current &&
      !bigAssistantsDropdownRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
      if (
        !(
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          assistantTopSelectRef.current &&
          !assistantTopSelectRef.current.contains(event.target)
        )
      ) {
        setShowDropdown(true);
      }
    }
  };

  // Event handler for click events
  const handleClickOutsideChatHistoryDropdown = (event) => {
    // If user clicks outside or inside of dropdown ref
    // Close the dropdown
    if (
      chatNameDropdownRef.current &&
      !chatNameDropdownRef.current.contains(event.target)
    ) {
      setShowChatHistory(false);
      if (
        !(
          chatNameDropdownRef.current &&
          !chatNameDropdownRef.current.contains(event.target) &&
          chatHistoryInfoRef.current &&
          !chatHistoryInfoRef.current.contains(event.target)
        )
      ) {
        setShowChatHistory(true);
      }
    }
  };

  const bigAssistantChangeClick = () => {
    setReferencedElementId("addy-big-assistant-select");
    setShowDropdown(true);
    // Show
  };

  const onShowChatHistoryClick = () => {
    setChatHistoryReferencedElementId("addy-chat-history-select");
    setShowChatHistory(true);
  };

  useEffect(() => {
    setTooltipSteps([
      {
        target: ".tooltip-1",
        title: "Private and Secure Chat",
        content:
          "All chats on Addy AI are private, secure, and only available to you.",
        disableBeacon: true,
      },
      {
        target: ".tooltip-2",
        title: "Choose Assistant or Create New",
        content:
          "Select an assistant to chat with or create a new one. Each assistant can be trained on different data.",
      },
      {
        target: ".tooltip-3",
        title: "Manage Assistants or Close Chat",
        content:
          "Click the expand icon to open the Addy AI dashboard. Here you can create and train custom AI assistants for your company. Close the chat by clicking the close icon.",
      },
      {
        target: ".tooltip-4",
        title: "Highlight Any Text on Your Web Page",
        content:
          "Press and hold the Control key, then highlight text on any web page. Your assistant can provide you with relevant information like a summary, explanation, or answer to questions about the text.",
      },
      {
        target: ".tooltip-5",
        title: "Suggested Prompts",
        content:
          "Need help getting started? Click on one of these prompt to start a conversation with your assistant.",
      },
      {
        target: ".tooltip-6",
        title: "Ask Your Assistant Anything",
        content:
          "Type your question or message here and click the send button to chat with your assistant.",
      },
    ]);
  }, []);

  // Add event listener for clicks on the document
  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleClickOutsideChatHistoryDropdown);
    // Set assistants
    user.getAssistants().then((assistants) => {
      setAssistants(assistants);
      if (assistants.length && !selectedAssistant) {
        // Set the first assistant as the selected assistant
        setSelectedAssistant(assistants[0]);
      }
      if (assistants.length && selectedAssistantID) {
        // Set the selected assistant
        const assistant = assistants.find(
          (assistant) => assistant.appID === selectedAssistantID
        );
        setSelectedAssistant(assistant);
      }
      if (assistants.length) {
        // Initialize the default assistant
        initializeDefaultAssistant(assistants);
      }
    });

    // Scroll to the bottom of the chat
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }

    // Clean up by removing the event listener on unmount
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener(
        "click",
        handleClickOutsideChatHistoryDropdown
      );
    };
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
    // console.log("Selected assistant in use effect", selectedAssistant);
  }, [messageTexts, selectedAssistant]);

  const onSendButtonClick = async (prompt, selectedText) => {
    if (!prompt || prompt === "" || prompt.trim === "") {
      // eslint-disable-next-line no-undef
      alert("Prompt cannot be empty");
      return;
    }
    // Change the view to chat to show the chat messages
    if (currentView === "select-assistant") setCurrentView("chat");

    // Append the user's message to the chat
    const userMessageId = uuidv4();
    const userMessageText = userMessage(prompt, selectedText, userMessageId);
    setMessageTexts((prevMessageTexts) => [
      ...prevMessageTexts,
      userMessageText,
    ]);
    // Create Thinking Text and append to messageTexts
    const assistantMessageId = uuidv4();
    const assistantMessageTextBuffer = assistantMessage(
      "",
      "thinking",
      assistantMessageId
    );
    setMessageTexts((prevMessageTexts) => [
      ...prevMessageTexts,
      assistantMessageTextBuffer,
    ]);

    // Get the assistant's response
    // console.log("Selected assistant", selectedAssistant);

    const requestParams = {
      language: "english",
      tone: "neutral",
      name: name,
      user_prompt: prompt,
      selected_text: selectedText,
    };

    const responses = await Assistant.getEmailReplyChat(
      selectedAssistant?.appID,
      selectedAssistant?.publicId,
      uid,
      hostName,
      CHAT_ID,
      prompt,
      selectedText,
      name,
      clientHostname,
      selectedChatHistory,
      requestParams,
      email,
      "addy-assistant-001",
      [],
      [],
      true
    );

    // const responses = await Assistant.sendMessage(
    //   selectedAssistant?.appID,
    //   selectedAssistant?.publicId,
    //   uid,
    //   hostName,
    //   CHAT_ID,
    //   prompt,
    //   selectedText,
    //   name,
    //   clientHostname,
    //   selectedChatHistory
    // );

    // Get the last assistant message which was thinking
    let assistantMessageElement = document.getElementById(
      `${assistantMessageId}`
    );
    if (!assistantMessageElement) {
      await sleep(10); // wait for the assistant message to render
      assistantMessageElement = document.getElementById(
        `${assistantMessageId}`
      );
      if (!assistantMessageElement) {
        console.error(
          "Assistant message element not found after waiting",
          assistantMessageId
        );
        return;
      }
    }

    // Type in the assistant's response
    const messageText = assistantMessageElement.querySelector(
      ".model-response-text"
    );
    const feedbackContainer = assistantMessageElement.querySelector(
      ".feedback-container"
    );
    let firstChunk = true;
    // Set currently typing to be true
    setCurrentlyTyping(true);
    for await (const response of responses) {
      let text = "";
      if (typeof response === "string") {
        
        if (response.includes("documents-fetched-")) {
          const documents = JSON.parse(response.split("documents-fetched-")[1]);
          const docObj = {
            documents: documents,
            messageId: messageTexts.length + 1,
          }
          console.log("Documents fetched", docObj);
          setDocumentsContext([...documentsContext, docObj]);
          text = "";
        } else {
          if (
            response &&
            typeof response === "string" &&
            response.split("data: ") &&
            response.split("data: ").length > 1
          ) {
            text = response.split("data: ")[1];
          } else {
            text = response;
          }
        }
      } else {
        // console.error("Response is not a string:", response);
        continue; // Skip the current iteration as response isn't a string.
      } // Produce a typing effect for the text
      // if(response && typeof response === "string" && response.split("data: ") && response.split("data: ").length > 1) {
      //   text = response.split("data: ")[1];
      // } else {
      //   text = response;
      // }
      // Produce a typing effect for the text
      if (firstChunk) {
        // Remove the loading part of the thinking element
        const thinkingElement = assistantMessageElement.querySelector(".dot");
        if (thinkingElement) thinkingElement.remove();

        // Make the message text visible
        messageText.classList.remove("hide");
        firstChunk = false;
      }
      // Create a typing effect
      for (let i = 0; i < text.length; i++) {
        // Append messageText message attribute to text at character i
        setMessageTexts((prevMessageTexts) => {
          const newMessageTexts = [...prevMessageTexts];
          newMessageTexts[newMessageTexts.length - 1].message += text.charAt(i);
          return newMessageTexts;
        });

        await sleep(5); // Sleep for 5 milliseconds to create typing effect
        // Scroll to the bottom of the chat
        if (chatHistoryRef.current) {
          chatHistoryRef.current.scrollTop =
            chatHistoryRef.current.scrollHeight;
        }
      }
    }
    // Set currently typing to be false
    setCurrentlyTyping(false);
    feedbackContainer.classList.remove("hide");

    // Save the chat locally
    saveCurrentChatLocally();
  };

  const saveCurrentChatLocally = () => {
    if (messageTexts.length) {
      const locallyStoredChat = {
        chatID: CHAT_ID,
        messages: messageTexts,
        appID: selectedAssistant.appID,
        publicId: selectedAssistant?.publicId,
        chatName: chatName,
      };
      localStorage.setItem(CHAT_ID, JSON.stringify(locallyStoredChat));
      // Save a map of the save chats and their local storage keys
      const savedChats =
        JSON.parse(localStorage.getItem("addy-ai-sidebar-chats")) || {};
      if (!savedChats[CHAT_ID]) {
        savedChats[CHAT_ID] = {
          chatName: chatName,
          chatID: CHAT_ID,
          clientHostname: clientHostname,
          publicId: selectedAssistant?.publicId,
          appID: selectedAssistant.appID,
          dateStarted: Date.now(), // Epoch time in milliseconds
          lastActive: Date.now(), // Epoch time in milliseconds
        };
        localStorage.setItem(
          "addy-ai-sidebar-chats",
          JSON.stringify(savedChats)
        );
      } else {
        // Chat exists, update the last active time
        savedChats[CHAT_ID].lastActive = Date.now();
        localStorage.setItem(
          "addy-ai-sidebar-chats",
          JSON.stringify(savedChats)
        );
      }
    }
  };

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onAssistantClick = (assistant) => {
    setSelectedAssistant(assistant);
    setShowDropdown(false);
  };

  const userMessage = (message, selectedText, messageId) => {
    return {
      message: message,
      selectedText: selectedText,
      name: name,
      type: "user",
      messageId: messageId,
      imageURL: "https://i.imgur.com/W1yNbq7.png",
    };
  };

  const assistantMessage = (message, action, messageId) => {
    return {
      message: message,
      name: selectedAssistant?.appName || "Addy AI",
      type: "assistant",
      messageId: messageId,
      action: action || "none",
      imageURL: "/assistant-icon.svg",
    };
  };

  const initializeDefaultAssistant = (assistants) => {
    // console.log("Initializing default assistant", assistants);
    const defaultAssistant = assistants.find(
      (assistant) =>
        assistant.priority && assistant.priority.toLowerCase() === "default"
    );

    if (defaultAssistant) {
      // Set default assistant on frontened
      setDefaultAssistant(defaultAssistant);
      setSelectedAssistant(defaultAssistant);
    }

    if (!defaultAssistant && assistants.length > 1) {
      // Set on frontend and update on backend
      setDefaultAssistant(assistants[0]); // Pick the first assistant
      // updateDefaultAssistant(assistants[1], "First Time Use");
    } else if (!defaultAssistant && assistants.length === 1) {
      setDefaultAssistant(assistants[0]);
    }
  };

  const updateDefaultAssistant = (assistant, e) => {
    // Click event did not come form the item
    if (!e) return;
    // Set the default assistant in the frontend and backend
    setDefaultAssistant(assistant);
    user.setDefaultAssistant(assistant.appID);
    // eslint-disable-next-line max-len
    // if (assistant.appID !== "addy-default-assistant-c66e0609-371e-49b2-bb4a-5186526b2a9d") {
    //     // Update the default assistant in the backend
    //     user.setDefaultAssistant(assistant.appID);
    // }
  };

  const getDateFormat = () => {
    const date = Date.now();
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleJoyrideCallback = (data) => {
    // eslint-disable-next-line no-unused-vars
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false
      // so we can restart if we click start again.
      setTooltipRun(false);
    }
  };

  const onCreateNewChatClick = () => {
    // Create a new chat
    // setChatID(uuidv4());
    // setMessageTexts([]);
    // setChatName(getDateFormat());
    // setCurrentView("select-assistant");
    // setShowChatHistory(false);
    // setShowDropdown(false);
    // For now just refresh the page
    window.location.reload();
  };

  const onChatTitleClickInChatHistoryDropdown = (chatID) => {
    // console.log("Chat ID", chatID);
    const chatMessages = JSON.parse(localStorage.getItem(chatID));
    const chatMessageTexts = chatMessages.messages;
    if (chatMessages && chatMessageTexts) {
      // Update the chat id
      setChatID(chatID);
      setSelectedChatHistory(chatMessages);
      // Updating assistant messages to replace the thinking "action"
      // with "response" if there's a message with
      // length > 0 in the assistant messages
      for (let i = 0; i < chatMessageTexts.length; i++) {
        if (
          chatMessageTexts[i].type === "assistant" &&
          chatMessageTexts[i].message.length > 0
        ) {
          chatMessageTexts[i].action = "response";
        }
      }
      setMessageTexts(chatMessageTexts);
      setChatName(chatMessages.chatName ? chatMessages.chatName : "Chat");
      // Set the selected assistant
      const assistant = assistants.find(
        (assistant) => assistant.appID === chatMessages.appID
      );
      setSelectedAssistant(assistant);
      // Set the default assistant
      initializeDefaultAssistant(assistants);
      // Set the current view to chat
      setCurrentView("chat");
      setShowChatHistory(false);
      setShowDropdown(false);
      // Scroll to the bottom of the chat
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    }
  };

  return (
    <div className="chat">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={tooltipRun}
        scrollToFirstStep
        showProgress
        steps={tooltipSteps}
        styles={{
          options: {
            // this styles the overlay color
            overlayColor: "rgba(0, 0, 0, 0.4)",
            primaryColor: "#7150e0",
          },
        }}
      />

      <section className="top">
        <header className="top-bar-new-message">
          <div className="f-r-a-m-e">
            <img
              className="addy-check-security tooltip-1"
              width="16"
              height="16"
              src="/lock-icon-24.png"
              data-toggle="tooltip"
              title="Your chat is private, secure, and only visible to you"
            />
            <div>
              <div
                ref={chatHistoryInfoRef}
                className="addy-chat-history-select-buton button"
                id="addy-chat-history-select"
                data-toggle="tooltip"
                title="Switch chats or create a new chat"
                onClick={onShowChatHistoryClick}
              >
                <div className="addy-assistant chat-history-name-text">
                  {chatName.length > 15
                    ? `${chatName.substring(0, 12)}...`
                    : chatName}
                </div>
                <img className="expand-icon" alt="" src="/expand.svg" />
              </div>
            </div>

            {showChatHistory && (
              <div>
                <ChatHistoryDropdown
                  displayPosition="bottom"
                  referencedElementId={chatHistoryReferencedElementId}
                  onCreateNewChatClick={onCreateNewChatClick}
                  onChatTitleClick={onChatTitleClickInChatHistoryDropdown}
                  ref={chatNameDropdownRef}
                />
              </div>
            )}
          </div>
          <div className="button-parent">
            <div
              ref={assistantTopSelectRef}
              className="button"
              id="addy-header-assistant-select"
              data-toggle="tooltip"
              title="Choose assistant"
              onClick={() => {
                setReferencedElementId("addy-header-assistant-select");
                setShowDropdown(true);
              }}
            >
              <div className="addy-assistant">
                {selectedAssistant
                  ? selectedAssistant.appName.length > 17
                    ? `${selectedAssistant.appName.substring(0, 16)}...`
                    : selectedAssistant.appName
                  : "Loading..."}
              </div>
              <img className="plus-icon" alt="" src="/plus.svg" />
              <img className="expand-icon tooltip-2" alt="" src="/expand.svg" />
            </div>
            {/* Show assistants drop down */}
            {showDropdown && (
              <div>
                <ChatAssistantDropDown2
                  displayPosition="bottom"
                  assistants={assistants}
                  selectedAssistant={selectedAssistant}
                  referencedElementId={referecedElementId}
                  defaultAssistant={defaultAssistant}
                  onAssistantClick={onAssistantClick}
                  updateDefaultAssistant={updateDefaultAssistant}
                  headerText="Assistants"
                  createNewLink="https://app.addy-ai.com"
                  ref={dropdownRef}
                  marginRight={
                    referecedElementId === "addy-header-assistant-select"
                      ? "0px"
                      : ""
                  }
                />
              </div>
            )}
            <div className="open-in-full-parent tooltip-3">
              {/* These images are being added
                            from the chrome extension
                            page to close and expand the sidebar chat */}
              {/* <img
                                className="open-in-full"
                                loading="lazy"
                                alt=""
                                src="/open-in-full.svg"
                            />
                            <img
                                className="close-icon"
                                loading="lazy"
                                alt=""
                                src="/close.svg"
                            /> */}
            </div>
          </div>
        </header>
        <div
          id="chat-history-parent"
          className={`text-field ${
            currentView == "select-assistant"
              ? "padding-top-30"
              : "padding-top-0"
          }`}
        >
          {currentView == "select-assistant" && (
            <div id="addy-big-assistant-select">
              {selectedAssistant !== null ? (
                <FRAME
                  selectedAssistant={selectedAssistant}
                  ref={bigAssistantsDropdownRef}
                  onClick={() => bigAssistantChangeClick()}
                  expand="/expand.svg"
                  showFRAME
                  title={
                    "Selected Assistant: " +
                      selectedAssistant?.appName.charAt(0).toUpperCase() +
                      selectedAssistant?.appName.slice(1) ||
                    "Choose your Assistant"
                  }
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          )}

          {
            <div
              className="addy-simple-vertical-scrollbar"
              id="addy-chat-info"
              style={{
                display: currentView == "select-assistant" ? "flex" : "none",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                // border: "1px solid #e0e0e0",
                overflowX: "scroll",
                // justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div className="f-r-a-m-e3 info-card-small">
                <h2 className="h2-heading1 small-h2-heading-new">🤖</h2>
                <div className="h2-heading-parent">
                  {/* <b className="h2-heading2 small-h2-heading-new">Create AI Assistants</b> */}
                  <div className="task-description">
                    <p className="chat-with-your small-description-text">
                      Train custom AI assistants with your docs
                    </p>
                  </div>
                </div>
              </div>
              <div className="f-r-a-m-e3 info-card-small">
                <h2 className="h2-heading3 small-h2-heading-new">🧠</h2>
                <div>
                  {/* <b className="h2-heading4 small-h2-heading-new">Knowledge Base Search</b> */}
                  <div className="task-description">
                    <p className="add-necessary-bits small-description-text">
                      Your assistants can help you find information
                    </p>
                  </div>
                </div>
              </div>
              <div className="f-r-a-m-e3 info-card-small">
                <h2 className="h2-heading3 small-h2-heading-new">😎</h2>
                <div>
                  {/* <b className="h2-heading4 small-h2-heading-new">Help With Tasks</b> */}
                  <div className="task-description">
                    <p className="add-necessary-bits small-description-text">
                      Your assistants can help with emails and tasks
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
          {/* Render the message texts */}
          {currentView == "chat" && (
            <div ref={chatHistoryRef} id="addy-chat-history">
              {messageTexts.map((item, index) => {
                return (
                  <MessageText
                    documents={
                      documentsContext.filter(
                        (doc) => doc.messageId === index
                      )[0]?.documents
                    }
                    message={item}
                    key={item.messageId || uuidv4()}
                    clientID={clientID}
                    isFirstMessage={index == 0}
                    isLastMessage={index == messageTexts.length - 1}
                  />
                );
              })}
            </div>
          )}

          <ButtonInstance
            hideHotTipContainer={hideHotTip}
            data={data}
            clientID={clientID}
            maxCharsPerLine={30}
            uid={uid}
            currentlyTyping={currentlyTyping}
            hideSuggestedPrompts={messageTexts.length > 0 ? true : false}
            numberOfMessagesInChat={messageTexts.length}
            onClick={(prompt, selectedText) =>
              onSendButtonClick(prompt, selectedText)
            }
          />
        </div>
      </section>
    </div>
  );
};

export default Chat;
